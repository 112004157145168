import React from "react";
import Button from "react-bootstrap/Button";

const Projectsdata = (props) => {
  return (
    <>
      {/* <div className="pro">
        <div class="work__img">
          <img src={props.img} />
          <h4 className="section-title">{props.title}</h4>
          <div className="projectsbtn">
            <a
              href={props.demo}
              class="btn2 btn-rounded btn-alternate mr-2 mr-md-5"
            >
              Live Demo
            </a>
            <a href={props.git} class="btn2 btn-rounded btn-contrast scrollto">
              GitHub Repo
            </a>
          </div>
          <div className="para-content">
            <p>
              {props.data}
              <Button variant="outline-primary">{props.button}</Button>{" "}
              <Button variant="outline-success">{props.button2}</Button>{" "}
              <Button variant="outline-warning">{props.button3}</Button>{" "}
              <Button variant="outline-danger">{props.button4}</Button>{" "}
              <Button variant="outline-info">{props.button5}</Button>{" "}
            </p>
          </div>
        </div>
      </div> */}

      <div className="pro">
        <div class="main">
          <div class="card-j">
          <img
            src={props.img}
            alt="nature image"
          />
          </div>
          <div class="subs">
            <span id="img-genre"><b>{props.button}</b></span>
            <span id="img-genre"><b>{props.button}</b></span>
            <span id="img-genre"><b>{props.button}</b></span>
            <span id="img-genre"><b>{props.button}</b></span>
            <span id="img-genre"><b>{props.button}</b></span>
           
          </div>
          <div class="card-j-body">
            <div class="card-j-title">
              <h2>{props.title}</h2>
            </div>
            <p>
              {props.data}
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. 
              Blanditiis et ut ipsam in asperiores corrupti, illo dolores ullam. 
              Vero id eaque cumque nihil neque, natus officia numquam qui, 
              excepturi eligendi doloremque possimus dolorum quibusdam illum.
            </p>
          </div>
          <div class="read-more">
          <a id="link-btn"
              href={props.demo}
              class="btn2 btn-rounded btn-alternate"
            >
              Live Demo
            </a>
            <a id="link-btn" href={props.git} class="btn2 btn-rounded btn-contrast scrollto">
              GitHub Repo
            </a>
            {/* <button id="read-more-btn">Read More</button> */}
          </div>

        </div>
      </div>
    </>
  );
};

export default Projectsdata;
