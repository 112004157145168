import React from "react";
import Footer from "./Footer";
const BubbleWrap = () => {
  return (
    <section class="section integration-bubbles">
      <div class="overflow-hidden container">
        <div class="row">
          <div class="col-md-6">
            <div class="bubbles-wrapper">
              <div class="animations m-0">
                <div class="bubble bg-contrast rounded-circle p-2 shadow icon icon-xl">
                  <img
                    src="https://raw.githubusercontent.com/diiviij/Reactjs-Challenges/00411a234d3bf742d5e3d94dfd15eb4864b443cf/ReactjsChallenges/src/components/Assets/img/autofixer.svg"
                    class="img-responsive"
                    alt=""
                  />
                  <span class="badge badge-contrast shadow-box">
                    Autoprefixer
                  </span>
                </div>
                <div class="bubble bg-contrast rounded-circle p-2 shadow icon icon-xxl">
                  <img
                    src="https://raw.githubusercontent.com/diiviij/Reactjs-Challenges/00411a234d3bf742d5e3d94dfd15eb4864b443cf/ReactjsChallenges/src/components/Assets/img/bootstrap.svg"
                    class="img-responsive"
                  />
                  <span class="badge badge-contrast shadow-box">Bootstrap</span>
                </div>

                <div class="bubble bg-contrast rounded-circle p-2 shadow icon icon-md">
                  <img
                    src="https://raw.githubusercontent.com/diiviij/Reactjs-Challenges/00411a234d3bf742d5e3d94dfd15eb4864b443cf/ReactjsChallenges/src/components/Assets/img/cordova.svg"
                    alt=""
                    class="img-responsive"
                  />
                  <span class="badge badge-contrast shadow-box">Cordova</span>
                </div>

                <div class="bubble bg-contrast rounded-circle p-2 shadow icon icon-lg">
                  <img
                    src="https://raw.githubusercontent.com/diiviij/Reactjs-Challenges/00411a234d3bf742d5e3d94dfd15eb4864b443cf/ReactjsChallenges/src/components/Assets/img/css.svg"
                    alt=""
                    class="img-responsive"
                  />
                  <span class="badge badge-contrast shadow-box">CSS</span>
                </div>

                <div class="bubble bg-contrast rounded-circle p-2 shadow icon icon-xl">
                  <img
                    src="https://raw.githubusercontent.com/diiviij/Reactjs-Challenges/00411a234d3bf742d5e3d94dfd15eb4864b443cf/ReactjsChallenges/src/components/Assets/img/font.svg"
                    alt=""
                    class="img-responsive"
                  />
                  <span class="badge badge-contrast shadow-box">
                    Fontawesome
                  </span>
                </div>

                <div class="bubble bg-contrast rounded-circle p-2 shadow icon icon-xxl">
                  <img
                    src="https://raw.githubusercontent.com/diiviij/Reactjs-Challenges/00411a234d3bf742d5e3d94dfd15eb4864b443cf/ReactjsChallenges/src/components/Assets/img/html.svg"
                    alt=""
                    class="img-responsive"
                  />
                  <span class="badge badge-contrast shadow-box">HTML</span>
                </div>

                <div class="bubble bg-contrast rounded-circle p-2 shadow icon icon-lg">
                  <img
                    src="https://raw.githubusercontent.com/diiviij/Reactjs-Challenges/00411a234d3bf742d5e3d94dfd15eb4864b443cf/ReactjsChallenges/src/components/Assets/img/js.svg"
                    alt=""
                    class="img-responsive"
                  />
                  <span class="badge badge-contrast shadow-box">
                    Javascript
                  </span>
                </div>

                <div class="bubble bg-contrast rounded-circle p-2 shadow icon icon-xxl">
                  <img
                    src="https://raw.githubusercontent.com/diiviij/Reactjs-Challenges/00411a234d3bf742d5e3d94dfd15eb4864b443cf/ReactjsChallenges/src/components/Assets/img/npm.svg"
                    alt=""
                    class="img-responsive"
                  />
                  <span class="badge badge-contrast shadow-box">Npm</span>
                </div>

                <div class="bubble bg-contrast rounded-circle p-2 shadow icon icon-xl">
                  <img
                    src="https://raw.githubusercontent.com/diiviij/Reactjs-Challenges/00411a234d3bf742d5e3d94dfd15eb4864b443cf/ReactjsChallenges/src/components/Assets/img/sass.svg"
                    alt=""
                    class="img-responsive"
                  />
                  <span class="badge badge-contrast shadow-box">SASS</span>
                </div>
                <div class="bubble bg-contrast rounded-circle p-2 shadow icon icon-lg">
                  <img
                    src="https://raw.githubusercontent.com/diiviij/Reactjs-Challenges/00411a234d3bf742d5e3d94dfd15eb4864b443cf/ReactjsChallenges/src/components/Assets/img/autofixer.svg"
                    alt=""
                    class="img-responsive"
                  />
                  <span class="badge badge-contrast shadow-box">
                    Autoprefixer
                  </span>
                </div>
                <div class="bubble bg-contrast rounded-circle p-2 shadow icon icon-md">
                  <img
                    src="https://raw.githubusercontent.com/diiviij/Reactjs-Challenges/00411a234d3bf742d5e3d94dfd15eb4864b443cf/ReactjsChallenges/src/components/Assets/img/bootstrap.svg"
                    alt=""
                    class="img-responsive"
                  />
                  <span class="badge badge-contrast shadow-box">Bootstrap</span>
                </div>
                <div class="bubble bg-contrast rounded-circle p-2 shadow icon icon-xxl">
                  <img
                    src="https://raw.githubusercontent.com/diiviij/Reactjs-Challenges/00411a234d3bf742d5e3d94dfd15eb4864b443cf/ReactjsChallenges/src/components/Assets/img/cordova.svg"
                    alt=""
                    class="img-responsive"
                  />
                  <span class="badge badge-contrast shadow-box">Cordova</span>
                </div>
                <div class="bubble bg-contrast rounded-circle p-2 shadow icon icon-xl">
                  <img
                    src="https://raw.githubusercontent.com/diiviij/Reactjs-Challenges/00411a234d3bf742d5e3d94dfd15eb4864b443cf/ReactjsChallenges/src/components/Assets/img/css.svg"
                    alt=""
                    class="img-responsive"
                  />
                  <span class="badge badge-contrast shadow-box">CSS</span>
                </div>
                <div class="bubble bg-contrast rounded-circle p-2 shadow icon icon-xxl">
                  <img
                    src="https://raw.githubusercontent.com/diiviij/Reactjs-Challenges/00411a234d3bf742d5e3d94dfd15eb4864b443cf/ReactjsChallenges/src/components/Assets/img/font.svg"
                    alt=""
                    class="img-responsive"
                  />
                  <span class="badge badge-contrast shadow-box">
                    Fontawesome
                  </span>
                </div>
                <div class="bubble bg-contrast rounded-circle p-2 shadow icon icon-md">
                  <img
                    src="https://raw.githubusercontent.com/diiviij/Reactjs-Challenges/00411a234d3bf742d5e3d94dfd15eb4864b443cf/ReactjsChallenges/src/components/Assets/img/font.svg"
                    alt=""
                    class="img-responsive"
                  />
                  <span class="badge badge-contrast shadow-box">HTML</span>
                </div>
                <div class="bubble bg-contrast rounded-circle p-2 shadow icon icon-lg">
                  <img
                    src="https://raw.githubusercontent.com/diiviij/Reactjs-Challenges/00411a234d3bf742d5e3d94dfd15eb4864b443cf/ReactjsChallenges/src/components/Assets/img/js.svg"
                    alt=""
                    class="img-responsive"
                  />
                  <span class="badge badge-contrast shadow-box">
                    Javascript
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="section-heading">
              <p class="text-alternate text-uppercase bold">
                Learn React by Building Real-World Projects{" "}
              </p>
              <h2 class="heading-line">Integrations to make it better</h2>
              <p class="lead text-muted">
                A series of 50 React.js projects, from beginner to advanced
                level, with free resources, documentation, and a more
                interactive website coming soon.
              </p>
            </div>
            <a class="more-link undefined" href="#">
              Explore all
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BubbleWrap;
