const Projectslist = [
  {
    title: "Tree Folder Using React",
    demo: "https://htgc68.csb.app/",
    git: " ",
    tag: " ",
  },
  {
    title: "Random Quote Generator ",
    demo: "https://ld3g76.csb.app/",
    git: " ",
    button: "#useState",
  },
  {
    title: "GitHub User Search",
    demo: "https://z4rj2r.csb.app/",
    git: " ",
    data: "will add soon",
  },
  {
    title: "NewsLetter",
    demo: "https://7cjzvd.csb.app/",
    git: "https://github.com/diiviij/Reactjs-Challenges/tree/main/faq-section",
  },

  {
    title: "FAQ-Section",
    demo: "https://jnxw7c.csb.app/",
    git: " ",
  },
  {
    title: "Hide-Box",
    demo: "",
    git: " ",
  },
  {
    title: "To-Shopping-List",
    demo: "https://424ypq.csb.app/",
    git: "https://github.com/diiviij/Reactjs-Challenges/tree/main/to-do-Shopping",
  },
  {
    title: "Toggle Buttom(Usetate)",
    demo: "https://scry4w.csb.app/",
    git: " ",
  },
  {
    title: "Image-Carousel",
    demo: "",
    git: " ",
  },
];

export default Projectslist;
