import React from "react";

const Footer = () => {
  return (
    <>
      <div class="site-footer section bg-dark text-contrast edge top-left">
        <div class="py-3 container">
          <div class="row gap-y text-center text-md-left row">
            <div class="mr-auto col-md-4">
              <p>
                <center>
                  Thank You for Visiting React Challenges, You can share you
                  feed to divijjain.me@gmail.com
                </center>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
